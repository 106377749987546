<template>
  <div class="mt-md-3 mx-md-8 mx-lg-16">
    <v-banner v-if="isAdmin() || isLeiter() || isStavo()">
      <v-row class="center px-0 mx-0">
        <v-btn class="ma-3" @click="$router.push('/events/new')">
          <v-icon>
            add
          </v-icon>
          Neues Event Erstellen
        </v-btn>
      </v-row>
    </v-banner>
    <v-row class="px-0 mx-0">
      <event
        class="mx-1 mx-md-3 mt-3 mb-3"
        v-for="event in unregisteredEvents"
        :key="event.id"
        :event="event"
      />
    </v-row>
    <v-row class="px-0 mx-0" v-if="registeredEvents.length > 0">
      <span class="ml-1 ml-md-3 mt-5 text-h6">Angemeldete Aktionen:</span>
    </v-row>
    <v-row class="px-0 mx-0">
      <event
        class="mx-1 mx-md-3 mt-3 mb-3"
        v-for="event in registeredEvents"
        :key="event.id"
        :event="event"
      />
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Event from "../components/events/Event";

export default {
  name: "Events",
  components: {
    event: Event
  },
  computed: {
    events() {
      return this.$store.state.events.events;
    },
    registrations() {
      return this.$store.state.events.registrations;
    },
    unregisteredEvents() {
      return this.events.filter(event => {
        return !this.registrations
          .map(registration => registration.eventId)
          .includes(event.id);
      });
    },
    registeredEvents() {
      return this.events.filter(event => {
        return this.registrations
          .map(registration => registration.eventId)
          .includes(event.id);
      });
    }
  },
  methods: {
    ...mapGetters("user", ["isAdmin", "isLeiter", "isStavo"]),
    ...mapActions("events", ["refreshEvents", "refreshRegistrations"])
  },
  mounted() {
    this.refreshEvents();
    this.refreshRegistrations();
  }
};
</script>

<style scoped></style>
