<template>
  <v-card width="600">
    <v-card-title>
      {{ event.name }}
      <v-spacer />
      <v-btn
        v-if="isAdmin() || isLeiter() || isStavo()"
        icon
        @click="toOperationalView()"
      >
        <v-icon>
          dashboard
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-divider />
    <v-card-text>
      <event-info-list :event="event" />
      <div v-if="event.description !== '<p></p>'">
        <v-divider />
        <div class="mt-3" v-html="event.description" />
      </div>
      <v-list-item v-if="isAdmin() || isLeiter() || isStavo()">
        <v-list-item-avatar>
          <v-icon>
            link
          </v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <div class="font-weight-light">
            Öffentlicher Registrierungslink:
          </div>
          <div style="white-space: nowrap">
            <a :href="getPublicEventLink()">{{ getPublicEventLink() }}</a>
          </div>
        </v-list-item-content>
      </v-list-item>
      <v-list class="py-0 my-0">
        <v-list-item
          @click="toRegistrationDetailView()"
          v-if="registrationStatus === 'Angemeldet'"
        >
          <v-list-item-avatar>
            <v-icon>
              edit
            </v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            Eigene Angaben einsehen
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="event.registrationPossible">
          <v-tooltip top :disabled="!deadlineOver">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-content v-bind="attrs" v-on="on">
                <v-btn
                  :disabled="deadlineOver"
                  color="warning"
                  v-if="registrationStatus === 'Angemeldet'"
                  @click="unregister(event.id)"
                >
                  Abmelden
                </v-btn>
                <v-btn
                  :disabled="deadlineOver"
                  color="success"
                  @click="
                    $router.push({
                      name: 'registration',
                      params: { eventId: event.id }
                    })
                  "
                  v-else-if="registrationStatus === 'Anmeldung begonnen'"
                >
                  Anmeldung Fortsetzen
                </v-btn>
                <v-btn
                  :disabled="deadlineOver"
                  color="success"
                  @click="startRegistration()"
                  v-else
                >
                  Anmelden
                </v-btn>
              </v-list-item-content>
            </template>
            <span>
              {{
                registrationStatus === "Angemeldet" ? "Abmeldung" : "Anmeldung"
              }}
              nach der Anmeldefrist nicht mehr möglich.
            </span>
          </v-tooltip>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import EventInfoList from "@/components/events/EventInfoList";

export default {
  name: "Event",
  components: { EventInfoList },
  props: {
    event: Object
  },
  computed: {
    registrationStatus() {
      let eventId = this.event.id;
      if (
        this.$store.state.events.registrations
          .map(reg => reg.eventId)
          .includes(eventId)
      ) {
        return "Angemeldet";
      } else if (
        this.$store.state.events.registrationsInProgress
          .map(reg => reg.eventId)
          .includes(eventId)
      ) {
        return "Anmeldung begonnen";
      } else {
        return "Nicht Angemeldet";
      }
    },
    deadlineOver() {
      return (
        new Date(this.event.registrationInformation.registrationDeadline) <=
        new Date().setHours(0, 0, 0, 0)
      );
    }
  },
  methods: {
    ...mapActions("events", ["beginRegistration", "unregister"]),
    ...mapGetters("user", ["isAdmin", "isLeiter", "isStavo"]),
    startRegistration() {
      this.beginRegistration(this.event.id);
      this.$router.push({
        name: "registration",
        params: { eventId: this.event.id }
      });
    },
    toOperationalView() {
      this.$router.push({
        name: "event-operation",
        params: { eventId: this.event.id }
      });
    },
    toRegistrationDetailView() {
      this.$router.push({
        name: "registration-detail",
        params: { eventId: this.event.id }
      });
    },
    getPublicEventLink() {
      return (
        process.env.VUE_APP_FRONTEND_BASE_URL +
        "/#/events/public/" +
        this.event.token
      );
    }
  }
};
</script>

<style scoped></style>
